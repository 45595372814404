<template>
  <div>
    <b-form-file
      style="width: 25%"
      placeholder="Select"
      drop-placeholder="Drop file here..."
      accept="image/*"
      @input="handleMultiFileChange($event, 'phaseattachment', 'projectmaster')"
    />
    <image-map :value="value" @input="(zones) => change({ zones })" />
  </div>
</template>

<script>
// import ImageMap from '../../../components/ImageMap.vue'
import { BFormFile } from "bootstrap-vue";
import axios from '@/components/axios';
export default {
  components: {
    ImageMap,
    BFormFile,
  },
  data() {
    return {
      value: {
        uuid: 3721,
        source:
          "http://n.sinaimg.cn/news/transform/20170330/K2Gk-fycwymx2694027.jpg",
      },
    };
  },
  methods: {
    change(changes) {},
    async handleMultiFileChange(e, type) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.value.source =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style>
</style>
